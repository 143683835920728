import Header from "../../Components/Header";
import "./style.css";
import back from "./back.png";
import Flip from "react-reveal/Flip";
import Bounce from "react-reveal/Bounce";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import t from "./t.png";
export default function Form() {
  const [tel, setTel] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const Navigate = useNavigate();
  function sendForm() {
    let formData = new FormData();
    formData.append("name", name);
    formData.append("tel", tel);
    formData.append("email", email);

    fetch("https://totamtotut.opora.digital/amocrm/webhook.php", {
      method: "POST",
      body: JSON.stringify({
        name: name,
        phone: tel,
        email: email,
        service: localStorage.vid,
      }),
    }).then((response) => {
      if (response.status == 200) {
        let form = new FormData();
        form.append("text", `Имя: ${name}; Email: ${email}; Телефон: ${tel}`);
        form.append("parse_mode", "html");

        fetch(
          "https://api.telegram.org/bot6819307849:AAF4SxFHDxtrNpNRlzmE4_crdGRC3vmazlw/sendMessage?chat_id=-4158104800",
          {
            method: "post",
            body: form,
          }
        );
      }
      Navigate(routes.thanks);
    });
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="Form">
      <div className="formHeader">
        <Header />
      </div>
      <Flip top delay={400}>
        <div className="form">
          <div className="formBody">
            <Bounce>
              <h3>Заполните форму</h3>
            </Bounce>
            <Bounce delay={200}>
              <h4>
                И в ближайшее время с вами свяжется <br /> наш специалист
              </h4>
            </Bounce>
            <div className="inputsForm">
              <input
                type="text"
                placeholder="Имя"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="inputsForm">
              <input
                type="tel"
                placeholder="Телефон"
                value={tel}
                onChange={(e) => setTel(e.target.value)}
              />
            </div>
            <div className="inputsForm">
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div
              className="sendForm"
              style={
                tel.length < 7 ? { opacity: "0.6", pointerEvents: "none" } : {}
              }
              onClick={() => {
                sendForm();
              }}
            >
              Получить
            </div>
            <img src={t} alt="" />
          </div>
        </div>
      </Flip>
    </div>
  );
}
