import { useNavigate } from "react-router-dom";
import main from "./main.png";
import ok from "./ok.svg";
import "./style.css";
import Flip from "react-reveal/Flip";
import Zoom from "react-reveal/Zoom";
export default function Thanks() {
  const Navigate = useNavigate();
  return (
    <div className="thanks" style={{ backgroundImage: "url(" + main + ")" }}>
      <div className="thanksBody">
        <Flip left>
          <img src={ok} alt="" />
        </Flip>
        <Zoom top delay={200}>
          <h4>Ваша заявка получена!</h4>
        </Zoom>
        <Zoom top delay={400}>
          <p>
            Наш сотрудник уже увидел вашу заявку и в самое ближайшее время
            свяжется с вами
          </p>
        </Zoom>
        <Zoom bottom delay={600}>
          <div className="mainTo" onClick={() => Navigate("/")}>
            Вернуться на главную
          </div>
        </Zoom>
      </div>
    </div>
  );
}
