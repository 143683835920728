import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";

import "./style.css";
import Zoom from "react-reveal/Zoom";
import { routes } from "../../routes";
import cot from "./cot.svg";
import tg from "./tg.svg";
import inst from "./inst.svg";
export default function Team() {
  const Navigate = useNavigate();
  return (
    <div className="Team">
      <Header />
      <h2 className="tikak">Наш блог</h2>
      <Zoom>
        <div className="blogType">
          <p>
            Про виды ВНЖ Испании и условия их получения, на что обратить
            внимание при выборе жилья, истории тех, кто релоцировался, советы,
            чек-листы, рекомендации и подборки от экспертов.
          </p>
        </div>
      </Zoom>
      <div className="soc">
        <p>И всё это в наших соц сетях!</p>
        <div>
          <img
            src={tg}
            alt=""
            onClick={() => window.open("https://t.me/totamtotut_relocation")}
          />
          <img
            src={inst}
            alt=""
            onClick={() =>
              window.open("https://www.instagram.com/totamtotut_relocation/")
            }
          />
        </div>
        <span>Подписывайтесь на нас</span>
        <img src={cot} alt="" />
      </div>
    </div>
  );
}
