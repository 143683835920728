import Header from "../../Components/Header";
import "./style.css";
import f1 from "./1.webp";
import f2 from "./2.webp";
import f3 from "./3.webp";
import f4 from "./4.webp";
import f5 from "./5.webp";
import f6 from "./6.webp";
import f7 from "./7.webp";
import f8 from "./8.webp";
import f9 from "./9.webp";
import YouTube from "react-youtube";
import { useEffect, useState } from "react";
export default function Reviews() {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      rel: 0,
    },
  };
  const [stocks, setStocks] = useState([]);

  useEffect(() => {
    let formData = new FormData();
    formData.append("ID", "25");

    fetch("https://app.opora.digital/getStocks.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setStocks(data);
      });
  }, []);
  return (
    <div className="reviews">
      <Header></Header>
      <h2 className="tikak">Отзывы</h2>
      <p className="dopC">От наших клиентов</p>
      <div className="firstSlider">
        <div className="sliderfTrain">
          <div className="f1Sem">
            <p>
              Консультация <br />
              <b>нашего эксперта Ольги</b>
            </p>
            <img src={f1} alt="" />
          </div>
          <div className="f1Sem">
            <p>
              Консультация <br />
              <b>нашего эксперта Ольги</b>
            </p>
            <img src={f2} alt="" />
          </div>
          <div className="f1Sem">
            <p>
              Отзыв с консультации <br />
              <b>с экспертом</b>
            </p>
            <img src={f3} alt="" />
          </div>
          <div className="f1Sem">
            <p>
              Одобрение ВНЖ
              <br />
              <b>Digital Nomad</b>
            </p>
            <img src={f4} alt="" />
          </div>
          <div className="f1Sem">
            <p>
              Одобрение
              <br />
              <b>французского шенгена</b>
            </p>
            <img src={f5} alt="" />
          </div>
          <div className="f1Sem">
            <p>
              Одобрение визы
              <br />
              <b>цифрового кочевника</b>
            </p>
            <img src={f6} alt="" />
          </div>
          <div className="f1Sem">
            <p>
              Одобрение ВНЖ
              <br />
              <b>Digital Nomad</b>
            </p>
            <img src={f7} alt="" />
          </div>
          <div className="f1Sem">
            <p>
              Консультация <br />
              <b>нашего эксперта Жени</b>
            </p>
            <img src={f8} alt="" />
          </div>
          <div className="f1Sem">
            <p>
              Консультация
              <br />
              <b>нашего эксперта Ольги</b>
            </p>
            <img src={f9} alt="" />
          </div>
        </div>
      </div>
      <h2 className="tikak" style={{ marginBottom: "5vw" }}>
        Видео отзывы
      </h2>
      <div className="firstSlider">
        <div className="sliderfTrain">
          {stocks &&
            stocks.length > 0 &&
            stocks.map((em, index) => {
              return (
                <div className="f1Sem" key={index}>
                  <p>
                    <p>
                      Видеоотзыв <br /> от клиента <b>#{index + 1}</b>
                    </p>
                  </p>
                  <YouTube
                    videoId={em.imgList}
                    opts={opts}
                    onReady={(e) => e.target.setVolume(100)}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
