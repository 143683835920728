import "./style.css";
import gal from "./img/gal.svg";
import { useEffect, useState } from "react";
import Header from "../../Components/Header";
import cat from "./img/cat.svg";
import { Zoom } from "react-reveal";
export default function Question() {
  const [q1, setQ1] = useState(1);
  const [FAQ, setFAQ] = useState([]);
  console.log(q1);
  useEffect(() => {
    let formData = new FormData();
    formData.append("ID", "25");
    fetch("https://app.opora.digital/getServices.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setFAQ(data);
      });
  }, []);
  return (
    <div className="question" id="section7">
      <Header></Header>
      <h2 className="tikak" style={{ marginBottom: "5vw" }}>
        FAQ
      </h2>
      <div className="questEm">
        {FAQ &&
          FAQ.length > 0 &&
          FAQ.map((em, index) => {
            return (
              <Zoom top key={index}>
                <div
                  className="vibi"
                  onClick={() => setQ1(index + 1)}
                  id={index + 1}
                >
                  {q1 == index + 1 && <img src={cat} alt="" className="kots" />}
                  <div className="polo">
                    <p>{em.cards[0].nameService}</p>
                    <img
                      src={gal}
                      alt=""
                      style={
                        q1 == index + 1 ? { transform: "rotate(180deg)" } : {}
                      }
                    />
                  </div>
                  <div
                    className="hiden"
                    style={
                      q1 == index + 1 && document.body.clientWidth > 640
                        ? { display: "block", height: "10vw" }
                        : document.body.clientWidth < 640 && q1 == index + 1
                        ? { display: "block", height: "auto" }
                        : {}
                    }
                  >
                    {em.type}
                  </div>
                </div>
              </Zoom>
            );
          })}
        {/* <Zoom top>
          <div className="vibi" onClick={() => setQ1(1)}>
            {q1 == 1 && <img src={cat} alt="" className="kots" />}
            <div className="polo">
              <p>Какую нужно показать минимальную зарплату для семьи?</p>
              <img
                src={gal}
                alt=""
                style={q1 == 1 ? { transform: "rotate(180deg)" } : {}}
              />
            </div>
            <div
              className="hiden"
              style={
                q1 == 1 && document.body.clientWidth > 640
                  ? { display: "block", height: "10vw" }
                  : document.body.clientWidth < 640 && q1 == 1
                  ? { display: "block", height: "42vw" }
                  : {}
              }
            >
              Нужен доход 200% от минимальной заработной платы - SMI - в
              Испании. Если хотим взять с собой супруга/у, прибавляем о
              основному заявителю 75% от SMI, если хотим взять еще и ребенка, +
              25% от SMI) = 1323 евро
            </div>
          </div>
        </Zoom>
        <Zoom top>
          <div className="vibi" onClick={() => setQ1(2)}>
            {q1 == 2 && <img src={cat} alt="" className="kots" />}
            <div className="polo">
              <p>Если брак неофициальный, чем и как заменить свидетельство?</p>
              <img
                src={gal}
                alt=""
                style={q1 == 2 ? { transform: "rotate(180deg)" } : {}}
              />
            </div>

            <div
              className="hiden"
              style={
                q1 == 2 && document.body.clientWidth > 640
                  ? { display: "block", height: "10vw" }
                  : document.body.clientWidth < 640 && q1 == 2
                  ? { display: "block", height: "57vw" }
                  : {}
              }
            >
              В Испании существует система Pareja de hecho – это
              зарегистрированное сожительство между двумя гражданами на
              территории Испании. Не является официальным браком, но
              предоставляет некоторые права сожителям. Таким образом вдвоем в
              Испанию можно переехать даже без официально зарегистрированного
              брака.
            </div>
          </div>
        </Zoom>
        <Zoom top>
          <div className="vibi" onClick={() => setQ1(3)}>
            {q1 == 3 && <img src={cat} alt="" className="kots" />}
            <div className="polo">
              <p>
                Какие требования к справке об отсутствии судимости? В каком виде
                она нужна? И к какому моменту?
              </p>
              <img
                src={gal}
                alt=""
                style={q1 == 3 ? { transform: "rotate(180deg)" } : {}}
              />
            </div>

            <div
              className="hiden"
              style={
                q1 == 3 && document.body.clientWidth > 640
                  ? { display: "block", height: "10vw" }
                  : document.body.clientWidth < 640 && q1 == 3
                  ? { display: "block", height: "57vw" }
                  : {}
              }
            >
              Справка об отсутствии судимости должна быть с апостилем. Апостиль
              можно получить только в МВД России. Поэтому можно заказать справку
              в РФ и забрать по доверенности (нотариальное заверение не нужно,
              рукописной достаточно). Нам понадобится только ее скан. Данная
              справка действует 3 месяца, поэтому старая справка не подойдет.
            </div>
          </div>
        </Zoom>
        <Zoom top>
          <div className="vibi" onClick={() => setQ1(4)}>
            {q1 == 4 && <img src={cat} alt="" className="kots" />}
            <div className="polo">
              <p>
                Какой минимальный срок пребывания в Испании с ВНЖ на три года?
              </p>
              <img
                src={gal}
                alt=""
                style={q1 == 4 ? { transform: "rotate(180deg)" } : {}}
              />
            </div>

            <div
              className="hiden"
              style={
                q1 == 4 && document.body.clientWidth > 640
                  ? { display: "block", height: "10vw" }
                  : document.body.clientWidth < 640 && q1 == 4
                  ? { display: "block", height: "42vw" }
                  : {}
              }
            >
              Для того, чтобы в дальнейшем была возможность продлить ВНЖ,
              необходимо находиться в Испании не менее 183 дней в году.  Для
              получения ПМЖ соответственно можно отсутствовать в стране в сумме
              не более 10 месяцев за 5 лет.
            </div>
          </div>
        </Zoom>
        <Zoom top>
          <div className="vibi" onClick={() => setQ1(5)}>
            {q1 == 5 && <img src={cat} alt="" className="kots" />}
            <div className="polo">
              <p>
                Как я могу получить шенген в Испанию, если не имею ВНЖ и не
                нахожусь в России?
              </p>
              <img
                src={gal}
                alt=""
                style={q1 == 5 ? { transform: "rotate(180deg)" } : {}}
              />
            </div>

            <div
              className="hiden"
              style={
                q1 == 5 && document.body.clientWidth > 640
                  ? { display: "block", height: "10vw" }
                  : document.body.clientWidth < 640 && q1 == 5
                  ? { display: "block", height: "31vw" }
                  : {}
              }
            >
              Шенген Испании можно получить в Армении, для этого нужно будет
              приехать туда и там подавать документы.
            </div>
          </div>
        </Zoom> */}
      </div>
    </div>
  );
}
