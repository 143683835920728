import "./style.css";
import back from "./back.svg";
import tel from "./tel.svg";
import { useRef } from "react";
import hd from "./hd.svg";
export default function Header() {
  const copyInputHolder = useRef(null);
  const copiedBox = useRef(null);
  const copyText = () => {
    copyInputHolder.current.select();
    document.execCommand("copy");
    copiedBox.current.style.bottom = "3vh";
    setTimeout(() => {
      copiedBox.current.style.bottom = "-15vh";
    }, 2000);
  };
  return (
    <div className="headerMain">
      <div
        className="back"
        onClick={() => {
          window.history.back();
        }}
      >
        <img src={back} alt="" />
        <p>Назад</p>
      </div>
      <input
        ref={copyInputHolder}
        value={"+84 38 242 5892"}
        className="dont_have_mavieID_inviter_name_phone"
        readOnly
        style={{ display: "none" }}
      ></input>
      <div
        className="tel"
        onClick={() => window.open("https://t.me/totamtotutassistant")}
      >
        <img src={hd} alt="" />
        <p>Задать вопрос</p>
      </div>
      <div ref={copiedBox} className="copied_phone">
        Номер телефона <br /> скопирован
      </div>
    </div>
  );
}
