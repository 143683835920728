import { useState } from "react";
import Header from "../../Components/Header";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import Bounce from "react-reveal/Bounce";
import Zoom from "react-reveal/Zoom";
import cat1 from "./cot1.svg";
import cat2 from "./cot2.svg";
import cat3 from "./cot3.svg";
export default function Services() {
  const [service, openService] = useState(0);
  const Navigate = useNavigate();

  return (
    <div className="services">
      <Header />
      <h2 className="tikak" style={{ marginBottom: "4vw" }}>
        Наши услуги
      </h2>
      <Bounce left>
        <div className="serviceItem">
          <div className="serviceItemHeader">
            <div className="titl">
              <p>ВНЖ Испании</p>
              <img src={cat1} alt="" id="cat1" />
            </div>
            <div className="prices">
              <p>от 990 €</p>
              <div
                className="ask"
                onClick={() => {
                  localStorage.setItem("vid", "ВНЖ Испании");
                  Navigate(routes.form);
                }}
              >
                Узнать подробнее
              </div>
            </div>
          </div>
        </div>
      </Bounce>
      <Bounce right>
        <div className="serviceItem">
          <div
            className="serviceItemHeader"
            onClick={(e) => {
              openService(1);
              e.target.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <div className="titl">
              <p>Digital Nomad Visa</p>
              <img src={cat2} alt="" id="cat2" />
            </div>
            <span>Подготовка + подача документов</span>
            <div className="prices">
              <p>от 990 €</p>
              <div
                className="ask"
                onClick={() => {
                  localStorage.setItem("vid", "Digital Nomad Visa");
                  Navigate(routes.form);
                }}
              >
                Приобрести пакет
              </div>
            </div>
          </div>
          {service == 1 && (
            <Zoom left duration={400}>
              <div>
                <ul
                  className="serviceUl"
                  style={
                    service != 1
                      ? { display: "none" }
                      : { transform: "scaleY(1)" }
                  }
                >
                  <h3>
                    <b>Этап 1.</b> Подготовка
                  </h3>
                  <li>
                    <span>1</span> Открываем инструкции по документам. Видео и
                    текстовый формат.
                  </li>
                  <li>
                    <span>2</span> Составляем и согласовываем с тобой план
                    действий, с учётом всех таймингов. Ментор всегда напомнит
                    отправить нужный документ.
                  </li>
                  <li>
                    <span>3</span>
                    Добавляем тебя в чат с экспертом и другими номадами. Там
                    можно спрашивать все вопросы, если что-то непонятно.
                  </li>
                  <li>
                    <span>4</span>
                    Ты готовишь документы под нашим чутким присмотром.
                  </li>
                  <div className="orange">
                    300 $ и начинаем собирать документы правильно!
                  </div>
                </ul>
                <ul
                  className="serviceUl"
                  style={
                    service != 1
                      ? { display: "none" }
                      : { transform: "scaleY(1)" }
                  }
                >
                  <h3>
                    <b>Этап 2.</b> Подача
                  </h3>
                  <li>
                    <span>1</span> Финально проверяем все документы.
                  </li>
                  <li>
                    <span>2</span>Перед приездом открываются переводчики и
                    скидки к ним.
                  </li>
                  <li>
                    <span>3</span>
                    Открываем видео инструкции как заполнять анкеты. Реагируем
                    на запросы и оперативно отгружаем документы, если нужно.
                  </li>
                  <li>
                    <span>4</span>
                    Пишем сопроводительные письма на испанском к твоему кейсу.
                  </li>
                  <li>
                    <span>5</span>
                    Ещё раз проверяем все документы и выделяем нужные места,
                    куда смотрят проверяющие.
                  </li>
                  <li>
                    <span>6</span>
                    Грузим в систему через ЭЦП.
                  </li>
                  <li>
                    <span>7</span>
                    Реагируем на запросы и оперативно отгружаем документы, если
                    нужно.
                  </li>
                  <div className="orange">600 $ и подаём документы</div>
                </ul>
              </div>
            </Zoom>
          )}
          <div
            className="spisok"
            onClick={(e) => {
              openService(1);
              e.target.scrollIntoView({ behavior: "smooth" });
            }}
            style={service == 1 ? { display: "none" } : {}}
          >
            <p className="ultra">
              <b>Описание пакета</b>{" "}
              <span>
                Смотреть{" "}
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="6" cy="6" r="5.5" stroke="#FF5C01" />
                  <path
                    d="M5.78787 8.21213C5.90503 8.32929 6.09497 8.32929 6.21213 8.21213L8.12132 6.30294C8.23848 6.18579 8.23848 5.99584 8.12132 5.87868C8.00416 5.76152 7.81421 5.76152 7.69706 5.87868L6 7.57574L4.30294 5.87868C4.18579 5.76152 3.99584 5.76152 3.87868 5.87868C3.76152 5.99584 3.76152 6.18579 3.87868 6.30294L5.78787 8.21213ZM5.7 4L5.7 8L6.3 8L6.3 4L5.7 4Z"
                    fill="#FF5C01"
                  />
                </svg>
              </span>
            </p>
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="7" cy="7.5" r="6.5" stroke="white" />
              <path
                d="M4.19995 6.1001L6.99995 8.9001L10.2666 6.1001"
                stroke="white"
              />
            </svg>
          </div>
        </div>
      </Bounce>
      <Bounce left>
        <div className="serviceItem">
          <div
            className="serviceItemHeader"
            onClick={(e) => {
              openService(2);
              e.target.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <div className="titl">
              <p>Startup Visa</p>
              <img src={cat3} alt="" id="cat3" />
            </div>
            <span>
              Подготовка бизнес-план enisa + подготовка + подача документов
            </span>
            <div className="prices">
              <p>от 4490 €</p>
              <div
                className="ask"
                onClick={() => {
                  localStorage.setItem(
                    "vid",
                    "Подготовка бизнес-план enisa + подготовка + подача документов"
                  );
                  Navigate(routes.form);
                }}
              >
                Приобрести пакет
              </div>
            </div>
          </div>{" "}
          {service == 2 && (
            <Zoom left duration={400}>
              <div>
                <ul
                  className="serviceUl"
                  style={
                    service != 2
                      ? { display: "none" }
                      : { transform: "scaleY(1)" }
                  }
                >
                  <h3>
                    <b>Этап 1.</b> Подготовка
                  </h3>
                  <li>
                    <span>1</span> Составляем профайл фаундера (CV + linkedin).
                  </li>
                  <li>
                    <span>2</span> Объединяем кофаундеров с пересекающимися
                    опытом в группы.
                  </li>

                  <div className="orange">
                    490 $ и начинаем искать кофаундеров
                  </div>
                </ul>
                <ul
                  className="serviceUl"
                  style={
                    service != 2
                      ? { display: "none" }
                      : { transform: "scaleY(1)" }
                  }
                >
                  <h3>
                    <b>Этап 2.</b> Подача
                  </h3>
                  <li>
                    <span>1</span> Разработка концепции стартапа с фаундерами
                    исходя из опыта фаундеров и актуальных трендов.
                  </li>
                  <li>
                    <span>2</span> Написание БП и дополнительных материалов
                    (техническая документация и т.д.)
                  </li>
                  <li>
                    <span>3</span>
                    Презентация идеи.
                  </li>
                  <li>
                    <span>4</span>
                    Работа над linkedin фаундеров.
                  </li>
                  <div className="orange">
                    2000 $ и начинаем работу над бизнес-планом
                  </div>
                </ul>
                <ul
                  className="serviceUl"
                  style={
                    service != 2
                      ? { display: "none" }
                      : { transform: "scaleY(1)" }
                  }
                >
                  <h3>
                    <b>Этап 3.</b> Подача
                  </h3>
                  <li>
                    <span>1</span> Подготовка остальных документов по
                    инструкциям.
                  </li>
                  <li>
                    <span>2</span> Перед приездом открываются переводчики и
                    скидки к ним.
                  </li>
                  <li>
                    <span>3</span>
                    Открываем видео инструкции как заполнять анкеты.
                  </li>
                  <li>
                    <span>4</span>
                    Пишем сопроводительные письма.
                  </li>
                  <li>
                    <span>5</span>
                    Подаём документы.
                  </li>
                  <div className="orange">2000 € и подаём документы</div>
                </ul>
              </div>
            </Zoom>
          )}
          <div
            className="spisok"
            onClick={(e) => {
              openService(2);
              window.scrollTo(0, 400);
            }}
            style={service == 2 ? { display: "none" } : {}}
          >
            <p className="ultra">
              <b>Описание пакета</b>{" "}
              <span>
                Смотреть{" "}
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="6" cy="6" r="5.5" stroke="#FF5C01" />
                  <path
                    d="M5.78787 8.21213C5.90503 8.32929 6.09497 8.32929 6.21213 8.21213L8.12132 6.30294C8.23848 6.18579 8.23848 5.99584 8.12132 5.87868C8.00416 5.76152 7.81421 5.76152 7.69706 5.87868L6 7.57574L4.30294 5.87868C4.18579 5.76152 3.99584 5.76152 3.87868 5.87868C3.76152 5.99584 3.76152 6.18579 3.87868 6.30294L5.78787 8.21213ZM5.7 4L5.7 8L6.3 8L6.3 4L5.7 4Z"
                    fill="#FF5C01"
                  />
                </svg>
              </span>
            </p>
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="7" cy="7.5" r="6.5" stroke="white" />
              <path
                d="M4.19995 6.1001L6.99995 8.9001L10.2666 6.1001"
                stroke="white"
              />
            </svg>
          </div>
        </div>
      </Bounce>
    </div>
  );
}
